// src/components/NewsForm.js
import React, { useState, useEffect } from 'react';
import {
  Container,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Switch,
  FormControlLabel,
  IconButton,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import { DataGridPro,LicenseInfo } from '@mui/x-data-grid-pro';
import { Add, Edit, Delete } from '@mui/icons-material';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';

const API_PATH = process.env.REACT_APP_API_PATH;

const EmailLog = () => {
LicenseInfo.setLicenseKey('9af075c09b5df7441264261f25d3d08eTz03ODI4MyxFPTE3MzEwNzgzMTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
const [logList, setLogList] = useState([]);

  // 獲取新聞列表
  const fetchEmailLogs = async () => {

      const response = await axios.get(`${API_PATH}/super/email`);
      setLogList(response.data.source);

  };

  useEffect(() => {
    fetchEmailLogs();
  }, []);


  // 定義 DataGrid 的列
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'manufacturerName', headerName: '廠商名稱', width: 100 },
    { field: 'manufacturerEmail', headerName: '信箱', width: 100 },
    { field: 'operationDateTime', headerName: '發送日期', width: 100 },
    { field: 'emailSent', headerName: '是否發送', width: 80 },
    { field: 'emailSendStatus', headerName: '狀態發送', width: 80,valueFormatter: (params) => params.value === 1 ? '成功' : '失敗', },
    { field: 'errorMessage', headerName: '錯誤消息', width: 80 },
    { field: 'eventType', headerName: '事件類型', width: 80 },
    { field: 'fine', headerName: '罰鍰金額', width: 80 },
  ];

  return (
    <Container maxWidth="lg">
      {/* 頁面標題和新增按鈕 */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          my: 2,
        }}
      >
        <h2>email logs</h2>
      </Box>

      {/* 新聞列表 */}
      <div style={{ height: 600, width: '100%' }}>
        <DataGridPro
          rows={logList}
          columns={columns}
          rowKeyGetter={(row) => row.id}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10, 20, 50]}
        />
      </div>
    </Container>
  );
};

export default EmailLog;
