// src/erp/Line/TextMessageForm.js

import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { TextField, Button, Grid, Typography, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { EmojiList } from '../Line/LineObjectList/EmojiList'; // 確保路徑正確
import axios from 'axios';

const API_PATH = process.env.REACT_APP_API_PATH;
const urlRegex = /(https:\/\/stickershop\.line-scdn\.net\/sticonshop\/v1\/sticon\/[a-zA-Z0-9]+\/android\/\d{3}\.png\?v=1)/g;

// 使用 forwardRef 包裝組件
const TextMessageForm = forwardRef(({ initialData }, ref) => {
    const [emojiCache, setEmojiCache] = useState(EmojiList);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [emojiImgs, setEmojiImgs] = useState([]);
    const [messageType, setMessageType] = useState('text');
    const [textMessage, setTextMessage] = useState('');
    const [renderedMessage, setRenderedMessage] = useState('');
    const [emojis, setEmojis] = useState([]);
    const [isEmojiDialogOpen, setEmojiDialogOpen] = useState(false);

    // 將 textMessage 中的 URL 替換為圖片
    const renderTextWithImages = () => {
        const parts = renderedMessage.split(urlRegex);
        return parts.map((part, index) => {
            if (urlRegex.test(part)) {
                return (
                    <img
                        key={index}
                        src={part}
                        alt={`img-${index}`}
                        style={{ maxWidth: '20px', display: 'inline-block', verticalAlign: 'middle' }} // 保持在同一行
                    />
                );
            } else {
                return (
                    <span key={index} style={{ display: 'inline', verticalAlign: 'middle' }}>
                        {part}
                    </span>
                );
            }
        });
    };

    // 提交表單的功能已移至父組件，這裡僅提供獲取表單資料的方法

    // Expose getMessageData method to parent via ref
    useImperativeHandle(ref, () => ({
        getMessageData: () => {
            return {
                type: 'text',
                text: textMessage,
                emojis: emojis.filter((emoji) => emoji.productId && emoji.emojiId).map(emoji => ({
                    index: emoji.index,
                    productId: emoji.productId,
                    emojiId: emoji.emojiId
                }))
            };
        }
    }));

    // 處理 Emoji 的變更
    const openEmojiDialog = () => {
        setEmojiDialogOpen(true);
    };

    // 關閉 Emoji 選擇框
    const closeEmojiDialog = () => {
        setEmojiDialogOpen(false);
    };

    // 當選擇 Emoji 時插入到文字並生成 Emoji 資料
    const handleEmojiSelect = (productId, emojiId, img) => {
        // 插入 $ 符號到當前的 textMessage
        const updatedText = `${textMessage} $`;
        setTextMessage(updatedText);
        setEmojiImgs([
            ...emojiImgs,
            img
        ]);
        // 生成對應的 Emoji 對象，並將其加入到 emojis 陣列
        setEmojis([
            ...emojis,
            {
                index: updatedText.length - 1, // $ 符號的位置
                productId: productId,
                emojiId: emojiId
            }
        ]);

        // 關閉選擇框
        closeEmojiDialog();
    };

    useEffect(() => {
        // 當 textMessage 改變時檢查是否有符號刪除
        const currentEmojiCount = (textMessage.match(/\$/g) || []).length;

        // 若 $ 符號數量少於 emojis 數量，則需要刪除對應的 emoji
        if (currentEmojiCount < emojis.length) {
            const updatedEmojis = emojis.slice(0, currentEmojiCount); // 保留前面對應數量的 emojis
            setEmojis(updatedEmojis); // 更新 emojis
        }

        // 檢查 textMessage 是否包含 "$"
        if (textMessage.includes('$')) {
            let dollarCount = 0;
            // 使用 replace 方法來替換所有的 "$" 符號
            const replacedMessage = textMessage.split('').map((char) => {
                if (char === '$' && emojiImgs[dollarCount]) {
                    const imgTag = emojiImgs[dollarCount];
                    dollarCount++;
                    return imgTag; // 返回圖片標籤
                }
                return char; // 返回非 "$" 的字符
            }).join('');

            setRenderedMessage(replacedMessage); // 更新渲染內容
        } else {
            setRenderedMessage(textMessage); // 無 "$" 時顯示原始訊息
        }
    }, [textMessage, emojiImgs, emojis]);

    useEffect(() => {
        if (initialData) {
            setMessageType(initialData.type || 'text');
            setTextMessage(initialData.text || '');
            setEmojis(initialData.emojis || []);
            setEmojiImgs(initialData.emojis ? initialData.emojis.map(emoji => emoji.imageUrl) : []);
        }
    }, [initialData]);

    return (
        <Grid container spacing={2} style={{ padding: '20px' }}>
            {/* 文字訊息輸入框 */}
            <Grid item xs={12}>
                <TextField
                    label="訊息文字"
                    value={textMessage}
                    onChange={(e) => setTextMessage(e.target.value)}
                    multiline
                    fullWidth
                    rows={4}
                />
                {/* 顯示處理後的文本 */}
                <div style={{ marginTop: '20px' }}>
                    {renderTextWithImages()}
                </div>
            </Grid>

            {/* Emoji 選擇按鈕 */}
            <Grid item xs={12} style={{ justifyItems: 'center' }}>
                <Button variant="text" onClick={openEmojiDialog} fullWidth>
                    插入 Emoji
                </Button>
            </Grid>

            {/* Emoji 選擇對話框 */}
            <Dialog open={isEmojiDialogOpen} onClose={closeEmojiDialog}>
                <DialogTitle>選擇 Emoji</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {Object.keys(emojiCache).map((productId) => (
                            <Grid item xs={1} key={productId}>
                                <Button
                                    variant="outlined"
                                    onClick={() => setSelectedProductId(productId)}
                                    sx={{
                                        padding: '8px', // 控制Button內的空間
                                        minWidth: 'auto', // 不設置最小寬度，使按鈕與圖片大小自適應
                                    }}
                                >
                                    <img
                                        src={`https://stickershop.line-scdn.net/sticonshop/v1/sticon/${productId}/android/001.png?v=1`}
                                        alt={`Product ${productId}`}
                                        style={{ width: '16px', height: '16px' }}
                                    />
                                </Button>
                            </Grid>
                        ))}
                        {/* 點擊後顯示對應的 emoji 列表 */}
                        {selectedProductId && (
                            <Grid container spacing={1} style={{ margin: 20 }}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {`Product ID: ${selectedProductId}`}
                                    </Typography>
                                </Grid>
                                {emojiCache[selectedProductId].map((emoji) => (
                                    <Grid item xs={2} key={emoji.emojiId} container justifyContent="center" alignItems="center">
                                        <Button
                                            variant="text"
                                            onClick={() => handleEmojiSelect(selectedProductId, emoji.emojiId, emoji.imageUrl)}
                                            style={{ padding: '0', minWidth: 'auto', display: 'flex', justifyContent: 'center' }}
                                        >
                                            <img
                                                src={emoji.imageUrl}
                                                alt={`emoji-${emoji.emojiId}`}
                                                style={{ width: '20px', height: '20px' }}
                                            />
                                        </Button>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
            </Dialog>
        </Grid>
    );
});

export default TextMessageForm;
