import { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Chip, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const API_PATH = process.env.REACT_APP_API_PATH;

// 將標籤按類別分組
const groupByCategory = (tags) => {
  return tags.reduce((groups, tag) => {
    const category = tag.category;
    if (!groups[category]) {
      groups[category] = [];
    }
    groups[category].push(tag);
    return groups;
  }, {});
};

export default function TagsManage() {  
  const [tags, setTags] = useState([]);
  const [groupedTags, setGroupedTags] = useState({});
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState({
    id: 0,
    tagName: '',
    category: '扭蛋',
  });

  // 獲取標籤資料
  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_PATH}/super/tags`);
      if (response.status === 200) { 
        setTags(response.data.source);
      }
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  // 打開標籤表單（新增或編輯）
  const handleTagForm = (tag) => {
    if(tag != null) {
      setRequest({
        id: tag.id,
        tagName: tag.tagName,
        category: tag.category
      });
    } else {
      setRequest({
        id: 0,
        tagName: "",
        category: "扭蛋"
      });
    }
    setOpen(true);
  };

  // 關閉標籤表單
  const handleTagCloseForm = () => {
    setOpen(false);
  };

  // 提交標籤表單
  const handleSubmit = async () => {    
    try {
      const response = await axios.post(`${API_PATH}/super/tag`, request);
      if (response.status === 200) {
        handleTagCloseForm();
        fetchData();
      }
    } catch (error) {
      console.error('Error submitting tag:', error.response ? error.response.data : error);
    }                    
  };

  // 處理表單輸入變化
  const handleInputChange = (event, propertyName) => {
    const value = event.target.value;
    setRequest((prevData) => ({
      ...prevData,
      [propertyName]: value,
    }));
  };

  // 刪除標籤
  const handleDeleteSubmit = async (id) => { 
    try {
      const response = await axios.delete(`${API_PATH}/super/tag?id=${id}`);
      if (response.status === 200) {
        fetchData();
      }
    } catch (error) {
      console.error('Error deleting tag:', error.response ? error.response.data : error);
    }  
  };

  // 初始化時獲取標籤資料
  useEffect(() => {
    fetchData();
  }, []); 

  // 監聽標籤變化，重新分組
  useEffect(() => {
    setGroupedTags(groupByCategory(tags));
  }, [tags]);

  return (
    <>
      <Box sx={{ height: 'auto', width: '90%', margin: 'auto', paddingTop: '20px' }}>
        <Grid container spacing={2} style={{ marginBottom: '1%' }}>
          {/* 標題 */}
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>      
            <Typography variant="h2" component="h2">
              標籤管理 
            </Typography>
          </Grid>

          {/* 新增標籤按鈕 */}
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>      
            <Button variant="contained" color="primary" onClick={() => handleTagForm(null)}>
              新增標籤
            </Button>
          </Grid>

          {/* 標籤列表 */}
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Stack spacing={2} sx={{ width: '100%' }}>
              {Object.keys(groupedTags).map((category) => (
                <div key={category}>
                  <Typography variant="h5" component="h5" gutterBottom>
                    {category}
                  </Typography>
                  <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
                    {groupedTags[category].map((tag) => (
                      <Chip
                        key={tag.id}
                        label={tag.tagName}
                        variant="outlined"
                        onClick={() => handleTagForm(tag)}
                        onDelete={() => handleDeleteSubmit(tag.id)}
                        sx={{ marginBottom: '8px' }}
                      />
                    ))}
                  </Stack>
                </div>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Box>

      {/* 標籤表單對話框 */}
      <Dialog 
        open={open} 
        onClose={handleTagCloseForm}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
        PaperProps={{
          style: {
            width: '300px',
          },
        }}
      >
        <DialogTitle style={{ textAlign: 'center', fontSize: '18px' }}>
          {request.id ? '編輯標籤' : '新增標籤'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ flexGrow: 1, mt: 1 }}>
            <Grid container spacing={2}>
              {/* 標籤類別選擇 */}
              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <InputLabel id="category-label">標籤專屬類別</InputLabel>
                  <Select
                    labelId="category-label"
                    id="category-select"
                    value={request.category}
                    label="標籤專屬類別"
                    onChange={(e) => handleInputChange(e, 'category')}
                  >
                    <MenuItem value='扭蛋'>扭蛋</MenuItem>
                    <MenuItem value='盲盒'>盲盒</MenuItem>
                    <MenuItem value='福袋'>福袋</MenuItem>
                    <MenuItem value='一番賞'>一番賞</MenuItem>
                    <MenuItem value='特別賞'>特別賞</MenuItem>
                    <MenuItem value='牆外世界'>牆外世界</MenuItem>
                    <MenuItem value='數碼世界'>數碼世界</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* 標籤名稱輸入 */}
              <Grid item xs={12}>
                <TextField
                  fullWidth 
                  size="small"
                  label="標籤名稱"
                  variant="outlined"
                  value={request.tagName}
                  onChange={(e) => handleInputChange(e, 'tagName')}
                />
              </Grid>
            </Grid>
          </Box> 
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            送出
          </Button>  
          <Button variant="outlined" onClick={handleTagCloseForm}>
            取消
          </Button>  
        </DialogActions>
      </Dialog>
    </>
  );
}
