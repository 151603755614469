// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const AdminConfig = [
  {
    title: '超級管理者列表',
    path: '/admin/superadmins',
    icon: icon('ic_analytics')
  },
  {
    title: '圖文選單',
    path: '/admin/richmenu',
    icon: icon('ic_analytics')
  },
  {
    title: '推播管理',
    path: '/admin/broadcast',
    icon: icon('ic_analytics')
  },
  {
    title: '活動發布',
    path: '/admin/activity',
    icon: icon('ic_analytics')
  },
  {
    title: '廠商列表',
    path: '/admin/manufacturers',
    icon: icon('ic_analytics')
  },
  {
    title: '廠商帳款',
    path: '/admin/saleamount',
    icon: icon('ic_analytics')
  },
  {
    title: '回饋統計',
    path: '/admin/feedback',
    icon: icon('ic_analytics')
  },
  {
    title: '顧客列表',
    path: '/admin/customers',
    icon: icon('ic_analytics')
  },
  {
    title: '賞品列表',
    path: '/admin/commodities',
    icon: icon('ic_analytics')
  },
  {
    title: '標籤管理',
    path: '/admin/tags',
    icon: icon('ic_analytics')
  },
  {
    title: '信件紀錄',
    path: '/admin/email',
    icon: icon('ic_analytics')
  },
  {
    title: '每日簽到設定',
    path: '/admin/everyday',
    icon: icon('ic_analytics')
  }
];

export default AdminConfig;
