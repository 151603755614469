// src/erp/BroadcastMessageForm.js

import React, { useState, useEffect, useRef } from 'react';
import {
  TextField,
  Button,
  MenuItem,
  Grid,
  TablePagination,
  Typography,
  Dialog, DialogActions, DialogContent, DialogTitle,Select ,Checkbox ,FormControlLabel,FormGroup 
} from '@mui/material';
import { LicenseInfo, DataGridPro } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextMessageForm from './Line/TextMessageForm';
import StickerMessageForm from './Line/StickerMessageForm';
import ImageMessageForm from './Line/ImageMessageForm';
import ImagemapMessageForm from './Line/ImagemapMessageForm';
import FlexMessageForm from './Line/FlexMessageForm';
import axios from 'axios';

const API_PATH = process.env.REACT_APP_API_PATH;

const messageTypes = [
  { value: 'text', label: '文字訊息' },
  { value: 'sticker', label: '貼圖訊息' },
  { value: 'image', label: '圖片訊息' },
  // { value: 'video', label: '影片訊息' },
  // { value: 'audio', label: '音訊訊息' },
  // { value: 'location', label: '位置訊息' },
  { value: 'imagemap', label: 'Imagemap 訊息' },
  // { value: 'template_buttons', label: '按鈕模板訊息' },
  // { value: 'template_confirm', label: '確認模板訊息' },
  { value: 'flexCarousel', label: '彈性訊息(輪播)' },
  // { value: 'flexBubble', label: '彈性訊息(單播)' },
];

const BroadcastMessageForm = () => {
  LicenseInfo.setLicenseKey('9af075c09b5df7441264261f25d3d08eTz03ODI4MyxFPTE3MzEwNzgzMTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

  // Data grid states
  const tomorrow = dayjs().add(1, 'day'); // 取得明天的日期
  const [time, setTime] = useState(tomorrow);
  const [isChecked, setIsChecked] = useState(false);
  const [people, setPeople] = useState(0);
  const [filterRows, setFilterRows] = useState([]);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [status, setStatus] = useState(0); // 用來存儲更改後的狀態
  const [message, setMessage] = useState(''); // 用來存儲備註

  const [messageType, setMessageType] = useState('text');
  const [selectedMessage, setSelectedMessage] = useState(null);
  const messageFormRef = useRef();

  const columns = [
    { field: 'id', headerName: 'ID', width: 30 },
    { field: 'manufacturerName', headerName: '廠商', width: 120 },
    {
      field: 'broadcastMessage',
      headerName: '廣告類別',
      width: 150,
      editable: false,
      valueFormatter: (params) => {
        const messages = params.value?.messages;

        if (messages && messages.length > 0) {
          const messageType = messages[0].type; // 提取第一個消息的類型 
          switch (messageType) {
            case 'sticker':
              return '貼圖訊息';
            case 'text':
              return '文字訊息';
            case 'image':
              return '圖片訊息';
            // 添加其他消息類型的處理
            case 'imagemap':
              return '圖片互動訊息';
            case 'flexCarousel':
              return '彈性訊息';
            default:
              return '彈性訊息';
          }
        }

        return '無訊息';
      },
    },
    {
      field: 'price',
      headerName: '價格',
      width: 90,
      editable: false,
    },
    {
      field: 'peopleAmount',
      headerName: '接觸人數',
      width: 90,
      editable: false,
    },
    {
      field: 'hasPass',
      headerName: '發送狀態',
      width: 100,
      editable: false,
      valueFormatter: (params) => {
        switch (params.value) {
          case true:
            return '已發送';
          case false:
            return '尚未發送';
          default:
            return '未知';
        }
      },
    },
    {
      field: 'isValidated',
      headerName: '審核狀態',
      width: 100,
      editable: false,
      valueFormatter: (params) => {
        switch (params.value) {
          case 1:
            return '審核通過';
          case 0:
            return '審核中';
          case -1:
            return '審核失敗';
          default:
            return '未知';
        }
      },
    },
    {
      field: 'createDate',
      headerName: '申請時間',
      width: 150,
      editable: false,
      valueFormatter: (params) => { 
        const date = new Date(params.value || new Date());
        const minutes = (`0${date.getUTCMinutes()}`).slice(-2); 
        const hours = (`0${date.getUTCHours()}`).slice(-2);     
        return `${date.getUTCFullYear()}/${date.getUTCMonth() + 1}/${date.getUTCDate()} ${hours}:${minutes}`;
      }, 
    },
    {
      field: 'broadcastDate',
      headerName: '預約時間',
      width: 150,
      editable: false,
      valueFormatter: (params) => { 
        const date = new Date(params.value || new Date());
        const minutes = (`0${date.getUTCMinutes()}`).slice(-2); 
        const hours = (`0${date.getUTCHours()}`).slice(-2);     
        return `${date.getUTCFullYear()}/${date.getUTCMonth() + 1}/${date.getUTCDate()} ${hours}:${minutes}`;
      }, 
    },
    {
      field: 'actions',
      headerName: '操作',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleDelete(params.row.id)} // 點擊時調用 handleDelete
        >
          刪除
        </Button>
      ),
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  

  // 當 Checkbox 改變時調用的處理函數
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleRowDoubleClick = (params) => {
    // 當雙擊行時，設置選定行並打開對話框
    setSelectedRow(params.row);
    setStatus(params.row.Success); // 初始化狀態
    setMessage(params.row.Msg); // 初始化備註
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    // 準備發送到後端的資料
    const requestData = {
      id: selectedRow.id,
      msg: message,
      success: status,
    };
  
    try {
      // 使用 axios 發送 PUT 請求
      const response = await axios.put(`${API_PATH}/super/broadcast`, requestData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.status === 200) {
        // 請求成功，關閉對話框
        fetchData();
        setOpen(false);
        // 可以在這裡進行狀態更新或重新加載數據
      } else {
        alert(`更新失敗: ${response.data.errors}`);
      }
    } catch (error) {
      console.error('發送 API 請求時發生錯誤:', error);
      alert(`發送 API 請求時發生錯誤: ${error.message}`);
    }
  };

  // Fetch data function
  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_PATH}/super/broadcast?pageNumber=${page+1}&pageSize=${rowsPerPage}`);

      if (response.status === 200) { 
        console.log('Fetched Data:', response.data); // 添加這行
        setFilterRows(response.data.source);
        setTotalRows(response.data.totalItemCount);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };
  
  const fetchOAData = async () => {
    try {
      const response = await axios.get(`${API_PATH}/super/fans`);
      if (response.status === 200) { 
        setPeople(response.data.source);      
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchOAData();
  }, [page, rowsPerPage]);

  const handleTestSubmit = async () => {
    let messageData = {};

    if (messageFormRef.current && messageFormRef.current.getMessageData) {
      messageData = messageFormRef.current.getMessageData();
    } else {
      alert('無法取得訊息資料');
      return;
    }

    const broadcastRequest = {
      Messages: [messageData],
      // NotificationDisabled: false // 根據需要設定，可選
    };
    try {
      const response = await axios.post(
        `${API_PATH}/super/oa/broadcast/test?messageType=${messageType}`,
        broadcastRequest
      );
      if (response.status === 200) { 
        alert('推播訊息成功');
        fetchData();
      }   
      // 可以在此處清空表單或進行其他操作
    } catch (error) {
      console.error('推播訊息失敗:', error);
      alert('推播訊息失敗');
    }
  };
  // Handle form submission
  const handleSubmit = async () => {
    let messageData = {};

    if (messageFormRef.current && messageFormRef.current.getMessageData) {
      messageData = messageFormRef.current.getMessageData();
    } else {
      alert('無法取得訊息資料');
      return;
    }

    const broadcastRequest = {
      Messages: [messageData],
      // NotificationDisabled: false // 根據需要設定，可選
    };
    try {
      const response = await axios.post(
        `${API_PATH}/super/oa/broadcast?messageType=${messageType}&isReserve=${isChecked}&broadcastDate=${time.format('YYYY-MM-DD HH:mm')}`,
        broadcastRequest
      );
      if (response.status === 200) { 
        alert('推播訊息成功');
        fetchData();
      }   
      // 可以在此處清空表單或進行其他操作
    } catch (error) {
      console.error('推播訊息失敗:', error);
      alert('推播訊息失敗');
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${API_PATH}/super/broadcast/${id}`);
      if (response.status === 200) {
        // 刪除成功後，從表格中移除該行
        fetchData();
      } else {
        alert('刪除失敗');
      }
    } catch (error) {
      console.error('刪除失敗:', error);
      alert('刪除失敗');
    }
  };

  // Handle row click
  const handleRowClick = (params) => {
    if (params.row.broadcastMessage && params.row.broadcastMessage.messages.length > 0) {
      const selectedMsg = params.row.broadcastMessage.messages[0];
      console.log('Selected Message:', selectedMsg); // 添加這行
      setMessageType(selectedMsg.type);
      setSelectedMessage(selectedMsg);
    }
  };

  // Render message form based on selected messageType
  const renderMessageForm = () => {
    if (!messageType) return null;

    switch (messageType) {
      case 'text':
        return <TextMessageForm ref={messageFormRef} initialData={selectedMessage} />;
      case 'sticker':
        return <StickerMessageForm ref={messageFormRef} initialData={selectedMessage} />;
      case 'image':
        return <ImageMessageForm ref={messageFormRef} initialData={selectedMessage} />;
      case 'imagemap':
        return <ImagemapMessageForm ref={messageFormRef} initialData={selectedMessage} />;
      case 'flexCarousel':
        return <FlexMessageForm ref={messageFormRef} initialData={selectedMessage} />;
      // case 'flexBubble':
      //   return <FlexMessageBubbleForm ref={messageFormRef} initialData={selectedMessage} />;
      default:
        return null;
    }
  };

  return (
    <Grid container spacing={3} style={{ padding: 20 }}>
      <Grid item xs={12}>
        <Typography variant="h4" style={{marginBottom:10}}>預計接觸粉絲數:{people}</Typography>
      </Grid>
      <Grid item xs={12} style={{ height: '500px' }}>
        <DataGridPro
          rows={filterRows}
          columns={columns}
          disableRowSelectionOnClick
          style={{ height: '100%', width: '100%' }}
          onRowClick={handleRowClick}
          onRowDoubleClick={handleRowDoubleClick}
        />
      </Grid>

      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <TablePagination
          component="div"
          count={totalRows}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[50, 100, 200, 500]}
        />
      </Grid>

      <Grid item xs={9}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DateTimePicker']}>
            <DateTimePicker
              label="想發送的時間 會在該時段發送"
              value={time}
              onChange={(newValue) => setTime(newValue)}
              inputFormat="YYYY-MM-DD HH:mm" // 控制輸入框顯示的格式
              renderInput={(params) => <TextField {...params} />}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Grid>
      <Grid item xs={3}>
        <FormGroup>
          <FormControlLabel required control={            
            <Checkbox
              checked={isChecked}  // 根據 state 設置是否勾選
              onChange={handleCheckboxChange}  // 當變化時觸發事件
            />} label="是否預約" />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <TextField
          select
          label="訊息類型"
          value={messageType}
          onChange={(e) => {
            setMessageType(e.target.value);
            setSelectedMessage(null); // 清空選取的訊息
          }}
          fullWidth
          variant="outlined"
        >
          {messageTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {/* Dynamic Message Form */}
      <Grid item xs={12}>
        {renderMessageForm()}
      </Grid>

      {/* Submit Button */}
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          style={{ maxWidth: '200px' }}
          onClick={handleSubmit}
        >
          送出推播訊息
        </Button>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          style={{ maxWidth: '200px' }}
          onClick={handleTestSubmit}
        >
          送出測試訊息
        </Button>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>更改狀態</DialogTitle>
        <DialogContent>
          <Select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            fullWidth
            label="狀態"
          >
            <MenuItem value={0}>審核中</MenuItem>
            <MenuItem value={1}>通過</MenuItem>
            <MenuItem value={-1}>未通過</MenuItem>
          </Select>
          <TextField
            margin="dense"
            label="備註"
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">取消</Button>
          <Button onClick={handleSave} color="primary">保存</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default BroadcastMessageForm;
