export const StickerList = {
    '446': Array.from({ length: 40 }, (_, i) => {
        const stickerId = (1988 + i).toString(); // StickerId從1988到2027
        return {
            StickerId: stickerId,
            imageUrl: `https://stickershop.line-scdn.net/stickershop/v1/sticker/${stickerId}/android/sticker.png`,
        };
    }),
    '789': Array.from({ length: 40 }, (_, i) => {
        const stickerId = (10855 + i).toString(); // StickerId從1988到2027
        return {
            StickerId: stickerId,
            imageUrl: `https://stickershop.line-scdn.net/stickershop/v1/sticker/${stickerId}/android/sticker.png`,
        };
    }),
    '1070': Array.from({ length: 40 }, (_, i) => {
        const stickerId = (10551376 + i).toString();
        return {
            StickerId: stickerId,
            imageUrl: `https://stickershop.line-scdn.net/stickershop/v1/sticker/${stickerId}/android/sticker.png`,
        };
    }),
    '6136': Array.from({ length: 40 }, (_, i) => {
        const stickerId = (1988 + i).toString(); 
        return {
            StickerId: stickerId,
            imageUrl: `https://stickershop.line-scdn.net/stickershop/v1/sticker/${stickerId}/android/sticker.png`,
        };
    }),
    '6325': Array.from({ length: 40 }, (_, i) => {
        const stickerId = (10979904 + i).toString(); 
        return {
            StickerId: stickerId,
            imageUrl: `https://stickershop.line-scdn.net/stickershop/v1/sticker/${stickerId}/android/sticker.png`,
        };
    }),
};
  