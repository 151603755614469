// src/erp/Line/StickerMessageForm.js

import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
} from '@mui/material';
import { StickerList } from '../Line/LineObjectList/StickerList'; // 確保路徑正確
import axios from 'axios';

const API_PATH = process.env.REACT_APP_API_PATH;

// 使用 forwardRef 包裝組件
const StickerMessageForm = forwardRef(({ initialData }, ref) => {
  const [selectedPackageId, setSelectedPackageId] = useState(null); // 當前選擇的貼圖包ID
  const [selectedStickerId, setSelectedStickerId] = useState(null); // 當前選擇的貼圖ID
  const [selectedStickerImageUrl, setSelectedStickerImageUrl] = useState(null); // 選擇的貼圖圖片URL
  const [isStickerDialogOpen, setStickerDialogOpen] = useState(false); // 控制貼圖選擇框的開關

  // 打開貼圖選擇對話框
  const openStickerDialog = () => {
    setStickerDialogOpen(true);
  };

  // 關閉貼圖選擇對話框
  const closeStickerDialog = () => {
    setStickerDialogOpen(false);
  };

  // 提交選擇的貼圖
  useImperativeHandle(ref, () => ({
    getMessageData: () => {
      return {
        type: 'sticker',
        packageId: selectedPackageId,
        stickerId: selectedStickerId,
        // 根據需要添加其他屬性，例如 quickReply、sender 等
      };
    }
  }));

  // 選擇貼圖時處理
  const handleStickerSelect = (productId, stickerId, imageUrl) => {
    setSelectedPackageId(productId);
    setSelectedStickerId(stickerId);
    setSelectedStickerImageUrl(imageUrl);
    closeStickerDialog(); // 選擇後關閉對話框
  };

  // 根據選擇的貼圖包和貼圖ID更新圖片URL
  useEffect(() => {
    if (selectedPackageId && selectedStickerId) {
      const sticker = StickerList[selectedPackageId]?.find(s => s.StickerId === selectedStickerId);
      if (sticker) {
        setSelectedStickerImageUrl(sticker.imageUrl);
      } else {
        setSelectedStickerImageUrl(null);
      }
    } else {
      setSelectedStickerImageUrl(null);
    }
  }, [selectedPackageId, selectedStickerId]);

  // 當接收到初始資料時，設定選擇的貼圖
  useEffect(() => {
    if (initialData) {
      const { packageId, stickerId } = initialData;
      if (packageId && stickerId) {
        setSelectedPackageId(packageId);
        setSelectedStickerId(stickerId);
        // imageUrl 將由上述 useEffect 根據 packageId 和 stickerId 自動設置
      }
    } else {
      // 如果沒有 initialData，清空選擇
      setSelectedPackageId(null);
      setSelectedStickerId(null);
      setSelectedStickerImageUrl(null);
    }
  }, [initialData]);

  return (
    <Grid container spacing={2} style={{ padding: '20px' }}>
      {/* 選擇貼圖按鈕 */}
      <Grid item xs={12}>
        <Button variant="contained" onClick={openStickerDialog} fullWidth>
          選擇貼圖
        </Button>
      </Grid>

      {/* 預覽區域 */}
      <Grid item xs={12}>
        <Typography variant="h6">訊息預覽</Typography>
        <Box
          sx={{
            border: '1px solid #ccc',
            padding: '16px',
            borderRadius: '8px',
            backgroundColor: '#f9f9f9',
            textAlign: 'center',
          }}
        >
          {selectedStickerImageUrl ? (
            <img
              src={selectedStickerImageUrl}
              alt={`Sticker ${selectedStickerId}`}
              style={{ width: '120px', height: '120px' }}
            />
          ) : (
            <Typography variant="body2" color="textSecondary">
              尚未選擇貼圖
            </Typography>
          )}
        </Box>
      </Grid>

      {/* 貼圖選擇對話框 */}
      <Dialog open={isStickerDialogOpen} onClose={closeStickerDialog} maxWidth="md" fullWidth>
        <DialogTitle>選擇貼圖</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {Object.keys(StickerList).map((productId) => (
              <Grid item xs={12} key={productId}>
                <Typography variant="subtitle1" style={{ marginBottom: '8px' }}>
                  {`貼圖包 ID: ${productId}`}
                </Typography>
                <Grid container spacing={1}>
                  {StickerList[productId].map((sticker) => (
                    <Grid item xs={3} sm={2} md={1} key={sticker.StickerId}>
                      <Button
                        onClick={() =>
                          handleStickerSelect(productId, sticker.StickerId, sticker.imageUrl)
                        }
                        style={{
                          padding: '0',
                          minWidth: 'auto',
                          width: '100%',
                          aspectRatio: '1 / 1', // 保持按鈕為正方形
                        }}
                      >
                        <img
                          src={sticker.imageUrl}
                          alt={`Sticker ${sticker.StickerId}`}
                          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                        />
                      </Button>
                    </Grid>
                  ))}
                </Grid>
                <Box
                  sx={{
                    marginTop: '16px',
                    marginBottom: '16px',
                    borderBottom: '1px solid #ccc',
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
});

export default StickerMessageForm;
