import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react';
import {
  Grid,
  TextField,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

const MAX_AREAS = 50;
const AREA_COLORS = ['red', 'blue', 'green', 'purple', 'orange', 'cyan'];
const API_PATH = process.env.REACT_APP_API_PATH;

const ImagemapMessageForm = forwardRef(({ initialData }, ref) => {
  // Basic settings state
  const [baseUrl, setBaseUrl] = useState('');
  const [altText, setAltText] = useState('');
  const [baseSize, setBaseSize] = useState({ width: 1040, height: 1040 });

  const [imageFile, setImageFile] = useState(null); // For image upload
  const [isUploading, setIsUploading] = useState(false);

  // Video settings state
  const [includeVideo, setIncludeVideo] = useState(false);
  const [videoData, setVideoData] = useState({
    originalContentUrl: '',
    previewImageUrl: '',
    area: { x: 0, y: 0, width: 0, height: 0 },
    externalLink: { linkUri: '', label: '' },
  });

  // Actions settings state
  const [actions, setActions] = useState([]);
  const [isActionDialogOpen, setActionDialogOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState({
    id: '',
    type: 'uri',
    linkUri: '',
    text: '',
    area: { x: 0, y: 0, width: 0, height: 0 },
  });

  // Canvas drawing state
  const [isDrawing, setIsDrawing] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [currentPosition, setCurrentPosition] = useState({ x: 0, y: 0 });
  const [imageUrl, setImageUrl] = useState('');
  const [loadedImage, setLoadedImage] = useState(null);
  const canvasRef = useRef(null);
  const SCALE_FACTOR = 0.5;

  // Minimum area size
  const MIN_WIDTH = 20;
  const MIN_HEIGHT = 20;

  // Function to handle image upload
  const handleImageUpload = async (file) => {
    // Check image format and size
    if (!file) return;
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      alert('圖片格式必須為 JPEG 或 PNG');
      return;
    }
    if (file.size > 10 * 1024 * 1024) {
      alert('圖片大小不能超過 10MB');
      return;
    }

    setIsUploading(true);

    try {
      // Assume you have a backend API to upload images and return the image URL
      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.post(
        `${API_PATH}/manufacturer/upload/imagemap`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const uploadedImageUrl = response.data.url; // Full image URL returned from backend
      setBaseUrl(response.data.baseUrl);
      setImageUrl(response.data.imageUrl);

      // Set image size
      const img = new Image();
      img.src = uploadedImageUrl;
      img.onload = () => {
        setBaseSize({ width: img.width, height: img.height });
      };
    } catch (error) {
      console.error('圖片上傳失敗:', error);
      alert('圖片上傳失敗');
    } finally {
      setIsUploading(false);
    }
  };

  // Initialize states from initialData
  useEffect(() => {
    if (initialData) { // 假設 initialData 通過 props 傳遞
      const data = initialData;

      // 設置基本屬性
      setBaseUrl(data.baseUrl || '');
      setAltText(data.altText || '');
      setBaseSize({
        width: data.baseSize?.width || 1040,
        height: data.baseSize?.height || 1040,
      });

      // 設置影片相關屬性
      if (data.Video) {
        setIncludeVideo(true);
        setVideoData(data.video);
      } else {
        setIncludeVideo(false);
        setVideoData({
          originalContentUrl: '',
          previewImageUrl: '',
          area: { x: 0, y: 0, width: 0, height: 0 },
          externalLink: { linkUri: '', label: '' },
        });
      }

      // 設置互動區域
      setActions(data.Actions || []);

      // 設置圖片 URL
      // 假設 backend 返回的 BaseUrl 已經包含圖片的基本路徑
      // 您可能需要根據實際情況調整 imageUrl 的生成方式
      if (data.baseUrl && data.baseSize) {
        // 假設圖片名稱是最大的寬度，如 1040.jpeg
        setImageUrl(`${data.baseUrl}/UploadFiles/ImagemapImages/1040`);
        // 或者根據實際的 imageUrl 字段
        // setImageUrl(data.ImageUrl || '');
      }
    }
  }, [initialData]);

  // Load Image when imageUrl changes
  useEffect(() => {
    if (imageUrl) {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        setLoadedImage(img);
        setBaseSize({ width: img.width, height: img.height });
      };
      img.onerror = () => {
        alert('無法加載圖片，請檢查 Base URL 和 Base Width');
      };
    }
  }, [imageUrl]);

  // Draw Areas on Canvas
  const drawAreas = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw Image
    if (loadedImage) {
      ctx.drawImage(
        loadedImage,
        0,
        0,
        baseSize.width * SCALE_FACTOR,
        baseSize.height * SCALE_FACTOR
      );
    }

    // Draw Existing Areas
    actions.forEach((area, index) => {
      ctx.strokeStyle = AREA_COLORS[index % AREA_COLORS.length];
      ctx.lineWidth = 2;
      ctx.strokeRect(
        area.x * SCALE_FACTOR,
        area.y * SCALE_FACTOR,
        area.width * SCALE_FACTOR,
        area.height * SCALE_FACTOR
      );
      ctx.font = '20px Arial';
      ctx.fillStyle = AREA_COLORS[index % AREA_COLORS.length];
      ctx.fillText(
        String.fromCharCode(97 + index),
        area.x * SCALE_FACTOR + 5,
        area.y * SCALE_FACTOR + 25
      );
    });

    // Draw Current Drawing Area
    if (isDrawing) {
      const x = Math.min(startPosition.x, currentPosition.x);
      const y = Math.min(startPosition.y, currentPosition.y);
      const width = Math.abs(currentPosition.x - startPosition.x);
      const height = Math.abs(currentPosition.y - startPosition.y);

      ctx.strokeStyle = 'blue';
      ctx.lineWidth = 2;
      ctx.strokeRect(
        x * SCALE_FACTOR,
        y * SCALE_FACTOR,
        width * SCALE_FACTOR,
        height * SCALE_FACTOR
      );

      // Semi-transparent fill
      ctx.fillStyle = 'rgba(0, 0, 255, 0.2)';
      ctx.fillRect(
        x * SCALE_FACTOR,
        y * SCALE_FACTOR,
        width * SCALE_FACTOR,
        height * SCALE_FACTOR
      );

      // Show Area Dimensions
      ctx.fillStyle = 'black';
      ctx.font = '16px Arial';
      ctx.fillText(
        `W: ${Math.round(width)} H: ${Math.round(height)}`,
        x * SCALE_FACTOR + 5,
        y * SCALE_FACTOR + 20
      );
    }
  };

  useEffect(() => {
    if (canvasRef.current) {
      drawAreas();
    }
  }, [baseSize, actions, isDrawing, currentPosition, loadedImage]);

  // Start Drawing
  const startDrawing = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    setStartPosition({
      x: (e.clientX - rect.left) / SCALE_FACTOR,
      y: (e.clientY - rect.top) / SCALE_FACTOR,
    });
    setIsDrawing(true);
  };

  // Update Current Position while Drawing
  const updateCurrentPosition = (e) => {
    if (!isDrawing) return;
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    setCurrentPosition({
      x: (e.clientX - rect.left) / SCALE_FACTOR,
      y: (e.clientY - rect.top) / SCALE_FACTOR,
    });
  };

  // Finish Drawing
  const finishDrawing = (e) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const endPosition = {
      x: (e.clientX - rect.left) / SCALE_FACTOR,
      y: (e.clientY - rect.top) / SCALE_FACTOR,
    };

    const x = Math.min(startPosition.x, endPosition.x);
    const y = Math.min(startPosition.y, endPosition.y);
    const width = Math.abs(endPosition.x - startPosition.x);
    const height = Math.abs(endPosition.y - startPosition.y);

    if (x < 0 || y < 0) {
      alert('區域無效，不能在畫布外框選');
      setIsDrawing(false);
      return;
    }

    if (width < MIN_WIDTH || height < MIN_HEIGHT) {
      alert(
        `區域太小，最小寬度為 ${MIN_WIDTH}px，最小高度為 ${MIN_HEIGHT}px`
      );
      setIsDrawing(false);
      return;
    }

    const newArea = {
      id: uuidv4(),
      x: x,
      y: y,
      width: width,
      height: height,
      action: {
        type: 'uri', // Default action type
        linkUri: '',
        text: '',
      },
    };

    if (actions.length >= MAX_AREAS) {
      alert(`最多只能框選 ${MAX_AREAS} 個區域`);
      setIsDrawing(false);
      return;
    }

    // Check for Overlapping Areas
    if (isAreaOverlapping(newArea)) {
      alert('區域重疊，請重新框選');
      setIsDrawing(false);
      return;
    }

    setActions([...actions, newArea]);

    setStartPosition({ x: 0, y: 0 });
    setCurrentPosition({ x: 0, y: 0 });
    setIsDrawing(false);
  };

  // Check for Overlapping Areas
  const isAreaOverlapping = (newArea) => {
    return actions.some((area) => {
      return !(
        newArea.x + newArea.width <= area.x ||
        newArea.x >= area.x + area.width ||
        newArea.y + newArea.height <= area.y ||
        newArea.y >= area.y + area.height
      );
    });
  };

  // Handle Area Change
  const handleAreaChange = (index, field, value) => {
    const newActions = [...actions];
    const area = { ...newActions[index] };

    if (field.startsWith('action.')) {
      const actionField = field.split('.')[1];
      const action = { ...area.action, [actionField]: value };
      area.action = action;
    } else {
      area[field] = value;
    }

    ['x', 'y', 'width', 'height'].forEach((key) => {
      if (area[key] !== undefined) {
        area[key] = parseFloat(area[key]) || 0;
      }
    });

    newActions[index] = area;

    // Check for Overlapping Areas
    const currentArea = area;
    const otherAreas = newActions.filter((_, i) => i !== index);

    const isOverlapping = otherAreas.some((otherArea) => {
      return !(
        currentArea.x + currentArea.width <= otherArea.x ||
        currentArea.x >= otherArea.x + otherArea.width ||
        currentArea.y + currentArea.height <= otherArea.y ||
        currentArea.y >= otherArea.y + otherArea.height
      );
    });

    if (isOverlapping) {
      alert('區域重疊，請調整區域屬性');
      return;
    }

    setActions(newActions);
  };

  // Delete Area
  const handleDeleteArea = (id) => {
    const updatedAreas = actions.filter((area) => area.id !== id);
    setActions(updatedAreas);
  };

  // Open Action Dialog for Adding a New Action
  const handleAddAction = () => {
    setCurrentAction({
      id: '',
      type: 'uri',
      linkUri: '',
      text: '',
      area: { x: 0, y: 0, width: 0, height: 0 },
    });
    setActionDialogOpen(true);
  };

  // Open Action Dialog for Editing an Existing Action
  const handleEditAction = (area, index) => {
    setCurrentAction({
      id: area.id,
      type: area.action.type,
      linkUri: area.action.linkUri || '',
      text: area.action.text || '',
      area: { x: area.x, y: area.y, width: area.width, height: area.height },
    });
    setActionDialogOpen(true);
  };

  // Close Action Dialog
  const handleCloseActionDialog = () => {
    setActionDialogOpen(false);
    setCurrentAction({
      id: '',
      type: 'uri',
      linkUri: '',
      text: '',
      area: { x: 0, y: 0, width: 0, height: 0 },
    });
  };

  // Save Action from Dialog
  const handleSaveAction = () => {
    const { id, type, linkUri, text, area } = currentAction;

    // Validation
    if (!area.width || !area.height || (!linkUri && !text)) {
      alert('請完整填寫 Action 資料和區域');
      return;
    }

    // Check for overlapping areas
    const newArea = {
      id: id || uuidv4(),
      x: parseInt(area.x, 10) || 0,
      y: parseInt(area.y, 10) || 0,
      width: parseInt(area.width, 10) || 0,
      height: parseInt(area.height, 10) || 0,
      action: {
        type: type,
        linkUri: linkUri,
        text: text,
      },
    };

    // Overlapping Check
    const overlapping = actions.some((a) => {
      if (id && a.id === id) return false; // Skip the current action if editing
      return !(
        newArea.x + newArea.width <= a.x ||
        newArea.x >= a.x + a.width ||
        newArea.y + newArea.height <= a.y ||
        newArea.y >= a.y + a.height
      );
    });

    if (overlapping) {
      alert('區域重疊，請重新設定區域');
      return;
    }

    if (id) {
      // Edit existing action
      const updatedActions = actions.map((a) => (a.id === id ? newArea : a));
      setActions(updatedActions);
    } else {
      // Add new action
      if (actions.length >= MAX_AREAS) {
        alert(`最多只能框選 ${MAX_AREAS} 個區域`);
        return;
      }
      setActions([...actions, newArea]);
    }

    handleCloseActionDialog();
  };

  // Expose getMessageData method to parent via ref
  useImperativeHandle(ref, () => ({
    getMessageData: () => {
      const mappedActions = actions.map((area) => ({
        Type: area.action.type,
        ...(area.action.type === 'uri'
          ? { LinkUri: area.action.linkUri }
          : { Text: area.action.text }),
        Area: {
          X: Math.round(area.x),
          Y: Math.round(area.y),
          Width: Math.round(area.width),
          Height: Math.round(area.height),
        },
      }));

      const message = {
        Type: 'imagemap',
        BaseUrl: baseUrl,
        AltText: altText,
        BaseSize: {
          Width: baseSize.width,
          Height: baseSize.height,
        },
        Video: includeVideo ? videoData : null,
        Actions: mappedActions,
      };

      return message;
    },
  }));

  return (
    <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
      <Typography variant="h5" gutterBottom>
        Imagemap Message 設定
      </Typography>
      <Grid container spacing={2}>
        {/* Basic Settings */}
        <Grid item xs={12}>
          <TextField
            label="Base URL"
            value={baseUrl}
            onChange={(e) => setBaseUrl(e.target.value)}
            fullWidth
            helperText="圖片的 Base URL（不包含圖片尺寸）"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" component="label">
            上傳圖片
            <input
              type="file"
              hidden
              accept="image/jpeg,image/png"
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  handleImageUpload(file);
                }
              }}
            />
          </Button>
        </Grid>
        {/* Preview Area */}
        <Grid item xs={12}>
          <Typography variant="h6">預覽圖片</Typography>
          <Box
            sx={{
              border: '1px solid #ccc',
              padding: '16px',
              borderRadius: '8px',
              backgroundColor: '#f9f9f9',
              textAlign: 'center',
            }}
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="原始圖片"
                style={{ maxWidth: '100%', maxHeight: '300px' }}
              />
            ) : (
              <Typography variant="body2" color="textSecondary">
                尚未上傳圖片
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Alt Text"
            value={altText}
            onChange={(e) => setAltText(e.target.value)}
            fullWidth
            helperText="當無法顯示 Imagemap 時顯示的文字"
            required
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Base Width"
            type="number"
            value={baseSize.width}
            onChange={(e) =>
              setBaseSize({
                ...baseSize,
                width: parseInt(e.target.value, 10) || 0,
              })
            }
            fullWidth
            helperText="Imagemap 圖片的寬度"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Base Height"
            type="number"
            value={baseSize.height}
            onChange={(e) =>
              setBaseSize({
                ...baseSize,
                height: parseInt(e.target.value, 10) || 0,
              })
            }
            fullWidth
            helperText="Imagemap 圖片的高度"
            required
          />
        </Grid>

        {/* Image Preview */}
        {loadedImage && (
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <canvas
              ref={canvasRef}
              width={baseSize.width * SCALE_FACTOR}
              height={baseSize.height * SCALE_FACTOR}
              style={{ 
                border: '1px solid black', 
                marginBottom: '20px',
                width: `${baseSize.width * SCALE_FACTOR}px`,
                height: `${baseSize.height * SCALE_FACTOR}px`,
              }}
              onMouseDown={startDrawing}
              onMouseMove={updateCurrentPosition}
              onMouseUp={finishDrawing}
            />
          </Grid>
        )}

        {/* Actions Management */}
        <Grid item xs={12}>
          <Typography variant="h6">互動事件 設定</Typography>
          <Button
            variant="contained"
            onClick={handleAddAction}
            style={{ marginTop: '8px', marginBottom: '8px' }}
          >
            添加 互動事件
          </Button>
        </Grid>

        {actions.length > 0 && (
          <Grid item xs={12}>
            {actions.map((area, index) => (
              <Paper
                key={area.id}
                style={{
                  padding: '10px',
                  marginTop: '10px',
                  backgroundColor: 'rgba(0, 255, 0, 0.1)',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      {`Action ${index + 1}`}
                    </Typography>
                    <Typography variant="body2">
                      {`Type: ${area.action.type}`}
                    </Typography>
                    {area.action.type === 'uri' ? (
                      <Typography variant="body2">
                        {`LinkUri: ${area.action.linkUri}`}
                      </Typography>
                    ) : (
                      <Typography variant="body2">
                        {`Text: ${area.action.text}`}
                      </Typography>
                    )}
                    <Typography variant="body2">
                      {`Area: X=${area.x}, Y=${area.y}, Width=${area.width}, Height=${area.height}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleEditAction(area, index)}
                    >
                      編輯 互動事件
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleDeleteArea(area.id)}
                      style={{ marginLeft: '8px' }}
                    >
                      刪除 互動事件
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </Grid>
        )}

        {/* Video Settings */}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={includeVideo}
                onChange={(e) => setIncludeVideo(e.target.checked)}
              />
            }
            label="包含影片"
          />
        </Grid>

        {includeVideo && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">影片設定</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Original Content URL"
                value={videoData.originalContentUrl}
                onChange={(e) =>
                  setVideoData({
                    ...videoData,
                    originalContentUrl: e.target.value,
                  })
                }
                fullWidth
                variant="outlined"
                helperText="影片的原始內容網址（HTTPS）"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Preview Image URL"
                value={videoData.previewImageUrl}
                onChange={(e) =>
                  setVideoData({
                    ...videoData,
                    previewImageUrl: e.target.value,
                  })
                }
                fullWidth
                variant="outlined"
                helperText="影片的預覽圖片網址（HTTPS）"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">影片區域設定</Typography>
              <Typography variant="body2" color="textSecondary">
                坐標系統的解釋
                <br />
                (0, 0)：左上角的坐標位置。
                <br />
                X 軸：從左向右遞增，表示水平位移。
                <br />
                Y 軸：從上往下遞增，表示垂直位移。
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    label="X"
                    value={videoData.area.x}
                    onChange={(e) =>
                      setVideoData({
                        ...videoData,
                        area: {
                          ...videoData.area,
                          x: parseInt(e.target.value, 10) || 0,
                        },
                      })
                    }
                    fullWidth
                    variant="outlined"
                    type="number"
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Y"
                    value={videoData.area.y}
                    onChange={(e) =>
                      setVideoData({
                        ...videoData,
                        area: {
                          ...videoData.area,
                          y: parseInt(e.target.value, 10) || 0,
                        },
                      })
                    }
                    fullWidth
                    variant="outlined"
                    type="number"
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Width"
                    value={videoData.area.width}
                    onChange={(e) =>
                      setVideoData({
                        ...videoData,
                        area: {
                          ...videoData.area,
                          width: parseInt(e.target.value, 10) || 0,
                        },
                      })
                    }
                    fullWidth
                    variant="outlined"
                    type="number"
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Height"
                    value={videoData.area.height}
                    onChange={(e) =>
                      setVideoData({
                        ...videoData,
                        area: {
                          ...videoData.area,
                          height: parseInt(e.target.value, 10) || 0,
                        },
                      })
                    }
                    fullWidth
                    variant="outlined"
                    type="number"
                    required
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Video External Link */}
            <Grid item xs={12}>
              <Typography variant="subtitle1">影片外部連結</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Link URI"
                value={videoData.externalLink.linkUri}
                onChange={(e) =>
                  setVideoData({
                    ...videoData,
                    externalLink: {
                      ...videoData.externalLink,
                      linkUri: e.target.value,
                    },
                  })
                }
                fullWidth
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Label"
                value={videoData.externalLink.label}
                onChange={(e) =>
                  setVideoData({
                    ...videoData,
                    externalLink: {
                      ...videoData.externalLink,
                      label: e.target.value,
                    },
                  })
                }
                fullWidth
                variant="outlined"
                required
              />
            </Grid>
          </>
        )}

      </Grid>

      {/* Action Dialog */}
      <Dialog
        open={isActionDialogOpen}
        onClose={handleCloseActionDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {currentAction.id ? '編輯 互動' : '添加 互動'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{marginTop:20}}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>互動類型</InputLabel>
                <Select
                  value={currentAction.type}
                  onChange={(e) =>
                    setCurrentAction({ ...currentAction, type: e.target.value })
                  }
                  label="互動類型"
                >
                  <MenuItem value="uri">路徑</MenuItem>
                  <MenuItem value="message">訊息</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {currentAction.type === 'uri' ? (
              <Grid item xs={12}>
                <TextField
                  label="點擊後轉導路由"
                  value={currentAction.linkUri}
                  onChange={(e) =>
                    setCurrentAction({
                      ...currentAction,
                      linkUri: e.target.value,
                    })
                  }
                  fullWidth
                  variant="outlined"
                  required
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <TextField
                  label="點擊後發送訊息"
                  value={currentAction.text}
                  onChange={(e) =>
                    setCurrentAction({ ...currentAction, text: e.target.value })
                  }
                  fullWidth
                  variant="outlined"
                  required
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="subtitle1">區域設定</Typography>
              <Typography variant="body2" color="textSecondary">
                手動輸入座標和尺寸
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    label="X"
                    value={currentAction.area.x}
                    onChange={(e) =>
                      setCurrentAction({
                        ...currentAction,
                        area: {
                          ...currentAction.area,
                          x: parseInt(e.target.value, 10) || 0,
                        },
                      })
                    }
                    fullWidth
                    variant="outlined"
                    type="number"
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Y"
                    value={currentAction.area.y}
                    onChange={(e) =>
                      setCurrentAction({
                        ...currentAction,
                        area: {
                          ...currentAction.area,
                          y: parseInt(e.target.value, 10) || 0,
                        },
                      })
                    }
                    fullWidth
                    variant="outlined"
                    type="number"
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Width"
                    value={currentAction.area.width}
                    onChange={(e) =>
                      setCurrentAction({
                        ...currentAction,
                        area: {
                          ...currentAction.area,
                          width: parseInt(e.target.value, 10) || 0,
                        },
                      })
                    }
                    fullWidth
                    variant="outlined"
                    type="number"
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Height"
                    value={currentAction.area.height}
                    onChange={(e) =>
                      setCurrentAction({
                        ...currentAction,
                        area: {
                          ...currentAction.area,
                          height: parseInt(e.target.value, 10) || 0,
                        },
                      })
                    }
                    fullWidth
                    variant="outlined"
                    type="number"
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseActionDialog}>取消</Button>
          <Button
            onClick={handleSaveAction}
            variant="contained"
            color="primary"
          >
            保存
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
});

export default ImagemapMessageForm;
