// src/erp/Line/ImageMessageForm.js

import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Box,
} from '@mui/material';
import axios from 'axios';

const API_PATH = process.env.REACT_APP_API_PATH;

// 使用 forwardRef 包裝組件
const ImageMessageForm = forwardRef(({ initialData }, ref) => {
  const [originalContentUrl, setOriginalContentUrl] = useState('');
  const [previewImageUrl, setPreviewImageUrl] = useState('');
  const [imageFile, setImageFile] = useState(null); // 用於上傳圖片
  const [previewImageFile, setPreviewImageFile] = useState(null); // 用於上傳預覽圖片
  const [isUploading, setIsUploading] = useState(false);

  // 處理圖片上傳
  const handleImageUpload = async (file, setUrl) => {
    // 檢查圖片格式與大小
    if (!file) return;
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      alert('圖片格式必須為 JPEG 或 PNG');
      return;
    }
    if (file.size > 10 * 1024 * 1024) {
      alert('圖片大小不能超過 10MB');
      return;
    }

    setIsUploading(true);

    try {
      // 這裡假設您有一個後端 API 可以上傳圖片並返回圖片的 URL
      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.post(`${API_PATH}/super/upload/image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const imageUrl = response.data.url; // 後端返回的圖片網址
      setUrl(imageUrl);
    } catch (error) {
      console.error('圖片上傳失敗:', error);
      alert('圖片上傳失敗');
    } finally {
      setIsUploading(false);
    }
  };

  // Expose getMessageData method to parent via ref
  useImperativeHandle(ref, () => ({
    getMessageData: () => {
      return {
        type: 'image',
        originalContentUrl,
        previewImageUrl,
        // 根據需要添加其他屬性，例如 quickReply、sender 等
      };
    }
  }));

  // 當接收到初始資料時，設定選擇的圖片
  useEffect(() => {
    if (initialData) {
      console.log('Initial Data:', initialData); // 確認 initialData
      setOriginalContentUrl(initialData.originalContentUrl || '');
      setPreviewImageUrl(initialData.previewImageUrl || '');
      // 如果需要處理 imageFile 和 previewImageFile，可以根據 initialData 進行設置
    } else {
      // 如果沒有 initialData，清空選擇
      setOriginalContentUrl('');
      setPreviewImageUrl('');
      setImageFile(null);
      setPreviewImageFile(null);
    }
  }, [initialData]);

  return (
    <Grid container spacing={2} style={{ padding: '20px' }}>
      {/* 原始圖片上傳或輸入網址 */}
      <Grid item xs={12}>
        <Typography variant="h6" style={{ marginBottom: 10 }}>原始圖片 (OriginalContentUrl)</Typography>
        <TextField
          label="原始圖片網址 (HTTPS)"
          value={originalContentUrl}
          onChange={(e) => setOriginalContentUrl(e.target.value)}
          fullWidth
          variant="outlined"
          style={{ marginBottom: '16px' }}
          disabled={isUploading}
        />
        <Button variant="contained" component="label" disabled={isUploading}>
          {isUploading ? '上傳中...' : '上傳圖片'}
          <input
            type="file"
            hidden
            accept="image/jpeg,image/png"
            onChange={(e) => {
              const file = e.target.files[0];
              setImageFile(file);
              handleImageUpload(file, setOriginalContentUrl);
            }}
          />
        </Button>
      </Grid>

      {/* 預覽區域 */}
      <Grid item xs={12}>
        <Typography variant="h6">預覽原始圖片</Typography>
        <Box
          sx={{
            border: '1px solid #ccc',
            padding: '16px',
            borderRadius: '8px',
            backgroundColor: '#f9f9f9',
            textAlign: 'center',
          }}
        >
          {originalContentUrl ? (
            <img
              src={originalContentUrl}
              alt="原始圖片"
              style={{ maxWidth: '100%', maxHeight: '300px' }}
            />
          ) : (
            <Typography variant="body2" color="textSecondary">
              尚未上傳原始圖片
            </Typography>
          )}
        </Box>
      </Grid>

      {/* 預覽圖片上傳或輸入網址 */}
      <Grid item xs={12}>
        <Typography variant="h6" style={{ marginBottom: 10 }}>預覽圖片 (PreviewImageUrl)</Typography>
        <TextField
          label="預覽圖片網址"
          value={previewImageUrl}
          onChange={(e) => setPreviewImageUrl(e.target.value)}
          fullWidth
          variant="outlined"
          style={{ marginBottom: '16px' }}
          helperText="選填 若原檔比較大，可以上傳尺寸較小的預覽圖"
          disabled={isUploading}
        />
        <Button variant="contained" component="label">
          上傳預覽圖片
          <input
            type="file"
            hidden
            accept="image/jpeg,image/png"
            onChange={(e) => {
              const file = e.target.files[0];
              setPreviewImageFile(file);
              handleImageUpload(file, setPreviewImageUrl);
            }}
          />
        </Button>
      </Grid>

      {/* 預覽區域 */}
      <Grid item xs={12}>
        <Typography variant="h6">瀏覽圖片預覽</Typography>
        <Box
          sx={{
            border: '1px solid #ccc',
            padding: '16px',
            borderRadius: '8px',
            backgroundColor: '#f9f9f9',
            textAlign: 'center',
          }}
        >
          {previewImageUrl ? (
            <img
              src={previewImageUrl}
              alt="預覽圖片"
              style={{ maxWidth: '100%', maxHeight: '300px' }}
            />
          ) : (
            <Typography variant="body2" color="textSecondary">
              尚未上傳預覽圖片
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
});


export default ImageMessageForm;
