// Template2Preview.js
import React from 'react';
import { Box, Typography, Button, Paper, Icon } from '@mui/material';

const FlexMessage1_2Preview = ({ flexMessage }) => {
  if (!flexMessage) {
    return <Typography>沒有 Flex Message 資料。</Typography>;
  }

  if (flexMessage.type !== 'carousel') {
    return <Typography>模板2 必須是 carousel 類型。</Typography>;
  }

  return (
    <Box display="flex" overflow="auto">
      {flexMessage.contents.map((bubble, index) => (
        <Paper key={index} style={{ minWidth: '300px', marginRight: '16px', padding: '16px', position: 'relative' }}>
          {/* 主圖像 */}
          {bubble.hero && (
            <img
              src={bubble.hero.url}
              alt="主圖像"
              style={{ width: '100%', height: 'auto', objectFit: 'cover', borderRadius: '8px' }}
            />
          )}
          {/* 正文 */}
          {bubble.body && (
            <Box mt={2}>
              {/* 標題 */}
              {bubble.body.contents.map((content, idx) => {
                if (content.type === 'text' && idx === 0) {
                  return (
                    <Typography
                      key={idx}
                      variant="h6"
                      style={{
                        fontWeight: 'bold',
                        color: content.color || '#000000',
                        fontSize: getFontSize(content.size),
                      }}
                    >
                      {content.text}
                    </Typography>
                  );
                }
                return null;
              })}
              {/* 評分 */}
              {bubble.body.contents.map((content, idx) => {
                if (content.type === 'box' && content.layout === 'baseline' && idx === 1) {
                  return (
                    <Box key={idx} display="flex" alignItems="center" mt={1}>
                      {content.contents.map((item, id) => (
                        <React.Fragment key={id}>
                          {item.type === 'icon' && item.url && (
                            <img src={item.url} alt="icon" style={{ width: '16px', height: '16px', marginRight: '4px' }} />
                          )}
                          {item.type === 'text' && (
                            <Typography
                              variant="body2"
                              style={{
                                color: item.color || '#8c8c8c',
                                fontSize: getFontSize(item.size),
                                marginLeft: '4px',
                              }}
                            >
                              {item.text}
                            </Typography>
                          )}
                        </React.Fragment>
                      ))}
                    </Box>
                  );
                }
                return null;
              })}
              {/* 地點 */}
              {bubble.body.contents.map((content, idx) => {
                if (content.type === 'box' && content.layout === 'vertical' && idx === 2) {
                  return (
                    <Box key={idx} mt={1}>
                      {content.contents.map((innerBox, innerIdx) => {
                        if (innerBox.type === 'box' && innerBox.layout === 'baseline') {
                          return (
                            <Box key={innerIdx} display="flex" alignItems="center">
                              {innerBox.contents.map((item, id) => (
                                <React.Fragment key={id}>
                                  {item.type === 'text' && (
                                    <Typography
                                      variant="body2"
                                      style={{
                                        color: item.color || '#8c8c8c',
                                        fontSize: getFontSize(item.size),
                                        marginRight: '4px',
                                      }}
                                    >
                                      {item.text}
                                    </Typography>
                                  )}
                                  {item.type === 'icon' && item.url && (
                                    <img src={item.url} alt="location icon" style={{ width: '16px', height: '16px' }} />
                                  )}
                                </React.Fragment>
                              ))}
                            </Box>
                          );
                        }
                        return null;
                      })}
                    </Box>
                  );
                }
                return null;
              })}
            </Box>
          )}
          {/* 頁尾按鈕 */}
          {bubble.footer && (
            <Box mt={2}>
              {bubble.footer.contents.map((button, btnIdx) => (
                <Button
                  key={btnIdx}
                  href={button.action.uri}
                  target="_blank"
                  fullWidth
                  style={{
                    backgroundColor: button.color || '#1DB446',
                    color: button.textColor || '#FFFFFF',
                    marginBottom: '8px',
                  }}
                >
                  {button.action.label}
                </Button>
              ))}
            </Box>
          )}
        </Paper>
      ))}
    </Box>
  );
};

// 根據文字大小返回對應的字體大小
const getFontSize = (size) => {
  switch (size) {
    case 'xxs':
      return '10px';
    case 'xs':
      return '12px';
    case 'sm':
      return '14px';
    case 'md':
      return '16px';
    case 'lg':
      return '18px';
    case 'xl':
      return '20px';
    default:
      return '14px';
  }
};

export default FlexMessage1_2Preview;
