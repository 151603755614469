// src/components/NewsForm.js
import React, { useState, useEffect } from 'react';
import {
  Container,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Switch,
  FormControlLabel,
  IconButton,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import { DataGridPro,LicenseInfo } from '@mui/x-data-grid-pro';
import { Add, Edit, Delete } from '@mui/icons-material';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';

const API_PATH = process.env.REACT_APP_API_PATH;

const NewsForm = () => {
LicenseInfo.setLicenseKey('9af075c09b5df7441264261f25d3d08eTz03ODI4MyxFPTE3MzEwNzgzMTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const [newsList, setNewsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const [newsData, setNewsData] = useState({
    id: 0,
    newsTitle: '',
    newsDetails: '',
    startDate: '',
    endDate: '',
    status: true,
    newsPhoto: null,
    sort: 0,
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // 獲取新聞列表
  const fetchNews = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_PATH}/super/news`, {
        headers: {
          // 如果需要授權，取消下面的註釋並設置 token
          // 'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      });
      setNewsList(response.data.source);
    } catch (error) {
      console.error('獲取新聞列表失敗', error);
      setSnackbar({
        open: true,
        message: '獲取新聞列表失敗',
        severity: 'error',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchNews();
  }, []);

  // 開啟新增新聞對話框
  const handleAddNews = () => {
    setSelectedNews(null);
    setNewsData({
      id: 0,
      newsTitle: '',
      newsDetails: '',
      startDate: '',
      endDate: '',
      status: true,
      newsPhoto: null,
      sort: 0,
    });
    setOpenFormDialog(true);
  };

  // 開啟編輯新聞對話框
  const handleEditNews = (news) => {
    setSelectedNews(news);
    setNewsData({
      id: news.id,
      newsTitle: news.newsTitle,
      newsDetails: news.newsDetails,
      startDate: news.startDate ? news.startDate.split('T')[0] : '',
      endDate: news.endDate ? news.endDate.split('T')[0] : '',
      status: news.status,
      newsPhoto: null,
      sort: news.sort,
    });
    setOpenFormDialog(true);
  };

  // 刪除新聞
  const handleDeleteNews = async (id) => {
    if (window.confirm('確定要刪除這條新聞嗎？')) {
      try {
        await axios.delete(`${API_PATH}/super/news?id=${id}`, {
          headers: {
            // 如果需要授權，取消下面的註釋並設置 token
            // 'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        });
        fetchNews();
        setSnackbar({
          open: true,
          message: '刪除成功',
          severity: 'success',
        });
      } catch (error) {
        console.error('刪除失敗', error);
        setSnackbar({
          open: true,
          message: '刪除失敗',
          severity: 'error',
        });
      }
    }
  };

  // 關閉表單對話框
  const handleCloseFormDialog = () => {
    setOpenFormDialog(false);
    setSelectedNews(null);
    setNewsData({
      id: 0,
      newsTitle: '',
      newsDetails: '',
      startDate: '',
      endDate: '',
      status: true,
      newsPhoto: null,
      sort: 0,
    });
  };

  // 處理表單字段變化
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewsData({ ...newsData, [name]: value });
  };

  // 處理開關變化
  const handleSwitchChange = (e) => {
    setNewsData({ ...newsData, status: e.target.checked });
  };

  // 處理文件上傳
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // 檢查文件類型是否為圖片
    if (!file.type.startsWith('image/')) {
      alert('請上傳圖片檔案');
      return;
    }

    // 創建一個 URL 用於加載圖片
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);

    img.onload = () => {
      const width = img.width;
      const height = img.height;
      URL.revokeObjectURL(objectUrl); // 釋放 URL

      const aspectRatio = height / width;
      const desiredRatio = 1 / 1.25; // 0.8
      const ratioTolerance = 0.02; // 誤差範圍

      // 驗證長寬比
      if (Math.abs(aspectRatio - desiredRatio) > ratioTolerance) {
        alert('請給正確尺寸 (長寬比應為 1:1.25)');
        return;
      }

      // 定義圖片尺寸範圍
      const minWidth = 750; // 最小寬度（根據需求調整）
      const minHeight = 600; // 最小高度（1.25倍寬度）
      const maxWidth = 3750; // 最大寬度（根據需求調整）
      const maxHeight = 3000; // 最大高度（1.25倍寬度）

      // 驗證圖片尺寸
      if (
        width < minWidth ||
        height < minHeight ||
        width > maxWidth ||
        height > maxHeight
      ) {
        alert(
          `圖片尺寸不符合要求。請確保寬度在 ${minWidth}px 至 ${maxWidth}px 之間，` +
          `高度在 ${minHeight}px 至 ${maxHeight}px 之間。`
        );
        return;
      }

      // 驗證通過，設置圖片文件到狀態
      setNewsData({ ...newsData, newsPhoto: file });
    };

    img.onerror = () => {
      alert('無法加載圖片，請嘗試其他文件');
      URL.revokeObjectURL(objectUrl); // 釋放 URL
    };

    img.src = objectUrl;
  };

  // 提交表單
  const handleSubmit = async () => {
    // 表單驗證
    if (
      !newsData.newsTitle ||
      !newsData.newsDetails ||
      !newsData.startDate ||
      !newsData.endDate ||
      !newsData.sort
    ) {
      setSnackbar({
        open: true,
        message: '請填寫所有必填字段',
        severity: 'warning',
      });
      return;
    }

    const formData = new FormData();
    formData.append('Id', newsData.id);
    formData.append('NewsTitle', newsData.newsTitle);
    formData.append('NewsDetails', newsData.newsDetails);
    formData.append('StartDate', newsData.startDate);
    formData.append('EndDate', newsData.endDate);
    formData.append('Status', newsData.status);
    formData.append('Sort', newsData.sort);
    if (newsData.newsPhoto) {
      formData.append('NewsPhoto', newsData.newsPhoto);
    }

    try {
      if (newsData.id === 0) {
        // 新增新聞
        await axios.post(`${API_PATH}/super/news`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            // 如果需要授權，取消下面的註釋並設置 token
            // 'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        });
        setSnackbar({
          open: true,
          message: '新增成功',
          severity: 'success',
        });
      } else {
        // 編輯新聞
        await axios.post(`${API_PATH}/super/news`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            // 如果需要授權，取消下面的註釋並設置 token
            // 'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        });
        setSnackbar({
          open: true,
          message: '更新成功',
          severity: 'success',
        });
      }
      fetchNews();
      handleCloseFormDialog();
    } catch (error) {
      console.error('操作失敗', error);
      setSnackbar({
        open: true,
        message: `操作失敗: ${error.response?.data?.message || error.message}`,
        severity: 'error',
      });
    }
  };

  // 關閉 Snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // 定義 DataGrid 的列
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'newsTitle', headerName: '標題', flex: 1 },
    {
      field: 'newsDetails',
      headerName: '內容',
      flex: 2,
      renderCell: (params) => (
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {parse(params.row.newsDetails || '')}
        </div>
      ),
    },
    { field: 'status', headerName: '上架中', width: 100 },
    { field: 'startDate', headerName: '開始日期', width: 150 },
    { field: 'endDate', headerName: '結束日期', width: 150 },
    {
      field: 'newsUrl',
      headerName: '圖片',
      width: 100,
      renderCell: (params) =>
        params.row.newsUrl ? (
          <img
            src={params.row.newsUrl}
            alt="新聞圖片"
            style={{ width: 50, height: 50, objectFit: 'cover' }}
          />
        ) : (
          '無圖片'
        ),
    },
    {
      field: 'actions',
      headerName: '操作',
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton color="primary" onClick={() => handleEditNews(params.row)}>
            <Edit />
          </IconButton>
          <IconButton color="secondary" onClick={() => handleDeleteNews(params.row.id)}>
            <Delete />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Container maxWidth="lg">
      {/* 頁面標題和新增按鈕 */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          my: 2,
        }}
      >
        <h2>新聞列表</h2>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={handleAddNews}
        >
          新增新聞
        </Button>
      </Box>

      {/* 新聞列表 */}
      <div style={{ height: 600, width: '100%' }}>
        <DataGridPro
          rows={newsList}
          columns={columns}
          loading={loading}
          rowKeyGetter={(row) => row.id}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10, 20, 50]}
          components={{
            LoadingOverlay: CircularProgress,
          }}
        />
      </div>

      {/* 新增/編輯新聞對話框 */}
      <Dialog
        open={openFormDialog}
        onClose={handleCloseFormDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{newsData.id === 0 ? '新增新聞' : '更新新聞'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* 標題 */}
            <Grid item xs={12}>
              <TextField
                label="新聞標題"
                name="newsTitle"
                value={newsData.newsTitle}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>

            {/* 內容 - 使用 React Quill */}
            <Grid item xs={12}>
              <ReactQuill
                theme="snow"
                value={newsData.newsDetails}
                onChange={(content, delta, source, editor) => {
                  setNewsData({ ...newsData, newsDetails: content });
                }}
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['link', 'image'],
                    ['clean'],
                  ],
                }}
                formats={[
                  'header',
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'list',
                  'bullet',
                  'link',
                  'image',
                ]}
                style={{ height: '200px', marginBottom: '50px' }}
              />
            </Grid>

            {/* 開始日期 */}
            <Grid item xs={6}>
              <TextField
                label="開始日期"
                name="startDate"
                type="date"
                value={newsData.startDate}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
                required
              />
            </Grid>

            {/* 結束日期 */}
            <Grid item xs={6}>
              <TextField
                label="結束日期"
                name="endDate"
                type="date"
                value={newsData.endDate}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="排序"
                name="sort"
                type="int"
                value={newsData.sort}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
                required
              />
            </Grid>

            {/* 狀態 */}
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={newsData.status}
                    onChange={handleSwitchChange}
                    color="primary"
                  />
                }
                label="啟用"
              />
            </Grid>

            {/* 圖片上傳 */}
            <Grid item xs={12}>
              <Button variant="contained" component="label">
                上傳圖片 (高寬比應為 1:1.25)
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </Button>
              {newsData.newsPhoto && (
                <span style={{ marginLeft: 10 }}>{newsData.newsPhoto.name}</span>
              )}
            </Grid>

            {/* 預覽圖片 */}
            {newsData.newsPhoto && (
              <Grid item xs={12}>
                <img
                  src={URL.createObjectURL(newsData.newsPhoto)}
                  alt="預覽"
                  style={{ width: '100%', maxHeight: '300px', objectFit: 'cover' }}
                />
              </Grid>
            )}
            {/* 編輯時顯示現有圖片 */}
            {selectedNews && selectedNews.newsUrl && !newsData.newsPhoto && (
              <Grid item xs={12}>
                <img
                  src={selectedNews.newsUrl}
                  alt="現有圖片"
                  style={{ width: '100%', maxHeight: '300px', objectFit: 'cover' }}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFormDialog}>取消</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            確定
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar 提示 */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default NewsForm;
