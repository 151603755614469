// Template3Preview.js
import React from 'react';
import { Box, Typography, Button, Paper } from '@mui/material';

const FlexMessage1_3Preview = ({ flexMessage }) => {
  if (!flexMessage) {
    return <Typography>沒有 Flex Message 資料。</Typography>;
  }

  if (flexMessage.type !== 'carousel') {
    return <Typography>模板3 必須是 carousel 類型。</Typography>;
  }

  return (
    <Box display="flex" overflow="auto">
      {flexMessage.contents.map((bubble, index) => (
        <Paper
          key={index}
          style={{
            minWidth: '300px',
            marginRight: '16px',
            padding: '16px',
            position: 'relative',
            borderRadius: '8px',
            overflow: 'hidden',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          {/* 主圖像 */}
          {bubble.hero && bubble.hero.url && (
            <img
              src={bubble.hero.url}
              alt="主圖像"
              style={{ width: '100%', height: 'auto', objectFit: 'cover', borderRadius: '8px' }}
            />
          )}
          {/* 正文 */}
          {bubble.body && (
            <Box mt={2} position="relative">
              {/* 商品圖片覆蓋 */}
              {bubble.body.contents.find(content => content.type === 'image') && (
                <img
                  src={bubble.body.contents.find(content => content.type === 'image').url}
                  alt="商品圖片"
                  style={{ width: '100%', height: 'auto', objectFit: 'cover', borderRadius: '8px' }}
                />
              )}
              {/* 商品資訊 */}
              {bubble.body.contents.map((content, idx) => {
                if (content.type === 'box' && content.layout === 'vertical') {
                  return (
                    <Box
                      key={idx}
                      position="absolute"
                      bottom="20px"
                      left="20px"
                      right="20px"
                      backgroundColor="rgba(3, 48, 58, 0.8)"
                      padding="16px"
                      borderRadius="4px"
                    >
                      {/* 商品名稱 */}
                      {content.contents.map((innerContent, innerIdx) => {
                        if (innerContent.type === 'box' && innerContent.layout === 'vertical') {
                          const productName = innerContent.contents[0]?.text || '商品名稱';
                          return (
                            <Typography
                              key={innerIdx}
                              variant="h6"
                              style={{
                                fontWeight: 'bold',
                                color: '#ffffff',
                                fontSize: '20px',
                                marginBottom: '8px',
                              }}
                            >
                              {productName}
                            </Typography>
                          );
                        }
                        return null;
                      })}
                      {/* 價格信息 */}
                      {content.contents.map((innerContent, innerIdx) => {
                        if (innerContent.type === 'box' && innerContent.layout === 'baseline') {
                          const price = innerContent.contents[0]?.text || '';
                          const originalPrice = innerContent.contents[1]?.text || '';
                          return (
                            <Box key={innerIdx} display="flex" alignItems="center" marginBottom="8px">
                              <Typography
                                variant="body1"
                                style={{
                                  color: '#ebebeb',
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  marginRight: '8px',
                                }}
                              >
                                {price}
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{
                                  color: '#ffffffcc',
                                  fontSize: '14px',
                                  textDecoration: 'line-through',
                                }}
                              >
                                {originalPrice}
                              </Typography>
                            </Box>
                          );
                        }
                        return null;
                      })}
                      {/* 添加購物車按鈕 */}
                      {content.contents.map((innerContent, innerIdx) => {
                        if (innerContent.type === 'box' && innerContent.layout === 'vertical') {
                          // 尋找包含 'Add to cart' 的 baseline box
                          const addToCartBox = innerContent.contents.find(
                            c => c.type === 'baseline' && c.contents.some(cc => cc.type === 'text' && cc.text === 'Add to cart')
                          );
                          if (addToCartBox) {
                            const iconUrl = addToCartBox.contents.find(c => c.type === 'icon')?.url;
                            const buttonText = addToCartBox.contents.find(c => c.type === 'text')?.text || 'Add to Cart';
                            // 檢查是否有 action，如果沒有，使用預設 href
                            const action = addToCartBox.contents.find(c => c.type === 'text')?.action;
                            const href = action?.uri || '#';

                            return (
                              <Button
                                key={innerIdx}
                                variant="contained"
                                color="secondary"
                                href={href}
                                startIcon={
                                  iconUrl ? (
                                    <img src={iconUrl} alt="cart" style={{ width: '16px', height: '16px' }} />
                                  ) : null
                                }
                                fullWidth
                                style={{
                                  backgroundColor: '#ff334b',
                                  color: '#ffffff',
                                  textTransform: 'none',
                                  marginTop: '8px',
                                }}
                              >
                                {buttonText}
                              </Button>
                            );
                          }
                        }
                        return null;
                      })}
                    </Box>
                  );
                }
                return null;
              })}
              {/* 促銷標籤 */}
              {bubble.body.contents.map((content, idx) => {
                if (content.type === 'box' && content.layout === 'vertical') {
                  return (
                    <Box
                      key={idx}
                      position="absolute"
                      top="18px"
                      left="18px"
                      backgroundColor="#ff334b"
                      borderRadius="20px"
                      padding="4px 12px"
                      height="25px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      width="53px"
                    >
                      <Typography variant="caption" style={{ color: '#ffffff', fontSize: '12px' }}>
                        SALE
                      </Typography>
                    </Box>
                  );
                }
                return null;
              })}
            </Box>
          )}
          {/* 頁尾按鈕 */}
          {bubble.footer && bubble.footer.contents && bubble.footer.contents.length > 0 && (
            <Box mt={2}>
              {bubble.footer.contents.map((button, btnIdx) => (
                <Button
                  key={btnIdx}
                  variant={button.style === 'primary' ? 'contained' : 'outlined'}
                  color={button.style === 'primary' ? 'primary' : 'secondary'}
                  href={button.action?.uri || '#'}
                  target="_blank"
                  fullWidth
                  style={{ marginBottom: '8px',backgroundColor: button.color || '#1DB446',
                    color: button.textColor || '#FFFFFF', }}
                >
                  {button.action?.label || 'Button'}
                </Button>
              ))}
            </Box>
          )}
        </Paper>
      ))}
    </Box>
  );
};

// 根據文字大小返回對應的字體大小
const getFontSize = (size) => {
  switch (size) {
    case 'xxs':
      return '10px';
    case 'xs':
      return '12px';
    case 'sm':
      return '14px';
    case 'md':
      return '16px';
    case 'lg':
      return '18px';
    case 'xl':
      return '20px';
    default:
      return '14px';
  }
};

export default FlexMessage1_3Preview;
