// LineObjectList/Templates.js
export const templates = [
    {
      label: '模板1',
      data: {
        type: "carousel",
        contents: [
          {
            type: "bubble",
            hero: {
              type: "image",
              size: "full",
              aspectRatio: "20:13",
              aspectMode: "cover",
              url: "https://developers-resource.landpress.line.me/fx/img/01_5_carousel.png"
            },
            body: {
              type: "box",
              layout: "vertical",
              spacing: "sm",
              contents: [
                {
                  type: "text",
                  text: "Arm Chair, White",
                  wrap: true,
                  weight: "bold",
                  size: "xl",
                  color: "#000000" // 添加默認顏色
                },
                {
                  type: "box",
                  layout: "baseline",
                  contents: [
                    {
                      type: "text",
                      text: "$49",
                      wrap: true,
                      weight: "bold",
                      size: "xl",
                      flex: 0,
                      color: "#000000"
                    },
                    {
                      type: "text",
                      text: ".99",
                      wrap: true,
                      weight: "bold",
                      size: "sm",
                      flex: 0,
                      color: "#000000"
                    }
                  ]
                }
              ]
            },
            footer: {
              type: "box",
              layout: "vertical",
              spacing: "sm",
              contents: [
                {
                  type: "button",
                  style: "primary",
                  action: {
                    type: "uri",
                    label: "Add to Cart",
                    uri: "https://line.me/"
                  }
                },
                {
                  type: "button",
                  action: {
                    type: "uri",
                    label: "Add to wishlist",
                    uri: "https://line.me/"
                  }
                }
              ]
            }
          },
          {
            type: "bubble",
            hero: {
              type: "image",
              size: "full",
              aspectRatio: "20:13",
              aspectMode: "cover",
              url: "https://developers-resource.landpress.line.me/fx/img/01_6_carousel.png"
            },
            body: {
              type: "box",
              layout: "vertical",
              spacing: "sm",
              contents: [
                {
                  type: "text",
                  text: "Metal Desk Lamp",
                  wrap: true,
                  weight: "bold",
                  size: "xl",
                  color: "#000000"
                },
                {
                  type: "box",
                  layout: "baseline",
                  flex: 1,
                  contents: [
                    {
                      type: "text",
                      text: "$11",
                      wrap: true,
                      weight: "bold",
                      size: "xl",
                      flex: 0,
                      color: "#000000"
                    },
                    {
                      type: "text",
                      text: ".99",
                      wrap: true,
                      weight: "bold",
                      size: "sm",
                      flex: 0,
                      color: "#000000"
                    }
                  ]
                },
                {
                  type: "text",
                  text: "Temporarily out of stock",
                  wrap: true,
                  size: "xxs",
                  margin: "md",
                  color: "#ff5551",
                  flex: 0
                }
              ]
            },
            footer: {
              type: "box",
              layout: "vertical",
              spacing: "sm",
              contents: [
                {
                  type: "button",
                  flex: 2,
                  style: "primary",
                  color: "#aaaaaa",
                  action: {
                    type: "uri",
                    label: "Add to Cart",
                    uri: "https://line.me/"
                  }
                },
                {
                  type: "button",
                  action: {
                    type: "uri",
                    label: "Add to wish list",
                    uri: "https://line.me/"
                  }
                }
              ]
            }
          },      
        ]
      }
    },
    {
      label: '模板2',
      data: {
        type: "carousel",
        contents: [
          {
            type: "bubble",
            size: "micro",
            hero: {
              type: "image",
              url: "https://developers-resource.landpress.line.me/fx/clip/clip10.jpg",
              size: "full",
              aspectMode: "cover",
              aspectRatio: "320:213"
            },
            body: {
              type: "box",
              layout: "vertical",
              contents: [
                {
                  type: "text",
                  text: "Brown Cafe",
                  weight: "bold",
                  size: "sm",
                  wrap: true,
                  color: "#000000"
                },
                {
                  type: "box",
                  layout: "baseline",
                  contents: [
                    {
                      type: "icon",
                      size: "xs",
                      url: "https://developers-resource.landpress.line.me/fx/img/review_gold_star_28.png"
                    },
                    {
                      type: "icon",
                      size: "xs",
                      url: "https://developers-resource.landpress.line.me/fx/img/review_gold_star_28.png"
                    },
                    {
                      type: "icon",
                      size: "xs",
                      url: "https://developers-resource.landpress.line.me/fx/img/review_gold_star_28.png"
                    },
                    {
                      type: "icon",
                      size: "xs",
                      url: "https://developers-resource.landpress.line.me/fx/img/review_gold_star_28.png"
                    },
                    {
                      type: "icon",
                      size: "xs",
                      url: "https://developers-resource.landpress.line.me/fx/img/review_gray_star_28.png"
                    },
                    {
                      type: "text",
                      text: "4.0",
                      size: "xs",
                      color: "#8c8c8c",
                      margin: "md",
                      flex: 0
                    }
                  ]
                },
                {
                  type: "box",
                  layout: "vertical",
                  contents: [
                    {
                      type: "box",
                      layout: "baseline",
                      spacing: "sm",
                      contents: [
                        {
                          type: "text",
                          text: "東京旅行",
                          wrap: true,
                          color: "#8c8c8c",
                          size: "xs",
                          flex: 5
                        }
                      ]
                    }
                  ]
                }
              ],
              spacing: "sm",
              paddingAll: "13px"
            },
            footer: {
              type: "box",
              layout: "vertical",
              spacing: "sm",
              contents: [
                // 可以根據需要添加 footer 按鈕
              ]
            }
          },
          {
            type: "bubble",
            size: "micro",
            hero: {
              type: "image",
              url: "https://developers-resource.landpress.line.me/fx/clip/clip11.jpg",
              size: "full",
              aspectMode: "cover",
              aspectRatio: "320:213"
            },
            body: {
              type: "box",
              layout: "vertical",
              contents: [
                {
                  type: "text",
                  text: "Brown&Cony's Restaurant",
                  weight: "bold",
                  size: "sm",
                  wrap: true,
                  color: "#000000"
                },
                {
                  type: "box",
                  layout: "baseline",
                  contents: [
                    {
                      type: "icon",
                      size: "xs",
                      url: "https://developers-resource.landpress.line.me/fx/img/review_gold_star_28.png"
                    },
                    {
                      type: "icon",
                      size: "xs",
                      url: "https://developers-resource.landpress.line.me/fx/img/review_gold_star_28.png"
                    },
                    {
                      type: "icon",
                      size: "xs",
                      url: "https://developers-resource.landpress.line.me/fx/img/review_gold_star_28.png"
                    },
                    {
                      type: "icon",
                      size: "xs",
                      url: "https://developers-resource.landpress.line.me/fx/img/review_gold_star_28.png"
                    },
                    {
                      type: "icon",
                      size: "xs",
                      url: "https://developers-resource.landpress.line.me/fx/img/review_gray_star_28.png"
                    },
                    {
                      type: "text",
                      text: "4.0",
                      size: "sm",
                      color: "#8c8c8c",
                      margin: "md",
                      flex: 0
                    }
                  ]
                },
                {
                  type: "box",
                  layout: "vertical",
                  contents: [
                    {
                      type: "box",
                      layout: "baseline",
                      spacing: "sm",
                      contents: [
                        {
                          type: "text",
                          text: "東京旅行",
                          wrap: true,
                          color: "#8c8c8c",
                          size: "xs",
                          flex: 5
                        }
                      ]
                    }
                  ]
                }
              ],
              spacing: "sm",
              paddingAll: "13px"
            },
            footer: {
              type: "box",
              layout: "vertical",
              spacing: "sm",
              contents: [
                // 可以根據需要添加 footer 按鈕
              ]
            }
          },
          {
            type: "bubble",
            size: "micro",
            hero: {
              type: "image",
              url: "https://developers-resource.landpress.line.me/fx/clip/clip12.jpg",
              size: "full",
              aspectMode: "cover",
              aspectRatio: "320:213"
            },
            body: {
              type: "box",
              layout: "vertical",
              contents: [
                {
                  type: "text",
                  text: "Tata",
                  weight: "bold",
                  size: "sm",
                  color: "#000000"
                },
                {
                  type: "box",
                  layout: "baseline",
                  contents: [
                    {
                      type: "icon",
                      size: "xs",
                      url: "https://developers-resource.landpress.line.me/fx/img/review_gold_star_28.png"
                    },
                    {
                      type: "icon",
                      size: "xs",
                      url: "https://developers-resource.landpress.line.me/fx/img/review_gold_star_28.png"
                    },
                    {
                      type: "icon",
                      size: "xs",
                      url: "https://developers-resource.landpress.line.me/fx/img/review_gold_star_28.png"
                    },
                    {
                      type: "icon",
                      size: "xs",
                      url: "https://developers-resource.landpress.line.me/fx/img/review_gold_star_28.png"
                    },
                    {
                      type: "icon",
                      size: "xs",
                      url: "https://developers-resource.landpress.line.me/fx/img/review_gray_star_28.png"
                    },
                    {
                      type: "text",
                      text: "4.0",
                      size: "sm",
                      color: "#8c8c8c",
                      margin: "md",
                      flex: 0
                    }
                  ]
                },
                {
                  type: "box",
                  layout: "vertical",
                  contents: [
                    {
                      type: "box",
                      layout: "baseline",
                      spacing: "sm",
                      contents: [
                        {
                          type: "text",
                          text: "東京旅行",
                          wrap: true,
                          color: "#8c8c8c",
                          size: "xs",
                          flex: 5
                        }
                      ]
                    }
                  ]
                }
              ],
              spacing: "sm",
              paddingAll: "13px"
            },
            footer: {
              type: "box",
              layout: "vertical",
              spacing: "sm",
              contents: [
                // 可以根據需要添加 footer 按鈕
              ]
            }
          }
        ]
      }
    },
    {
      label: '模板3',
      data: {
        type: "carousel",
        contents: [
          {
            type: "bubble",
            hero: null, // 如果不需要 hero，設為 null
            body: {
              type: "box",
              layout: "vertical",
              contents: [
                {
                  type: "image",
                  url: "https://developers-resource.landpress.line.me/fx/clip/clip1.jpg",
                  size: "full",
                  aspectMode: "cover",
                  aspectRatio: "2:3",
                  gravity: "top"
                },
                {
                  type: "box",
                  layout: "vertical",
                  contents: [
                    {
                      type: "box",
                      layout: "vertical",
                      contents: [
                        {
                          type: "text",
                          text: "Brown's T-shirts",
                          size: "xl",
                          color: "#ffffff",
                          weight: "bold"
                        }
                      ]
                    },
                    {
                      type: "box",
                      layout: "baseline",
                      contents: [
                        {
                          type: "text",
                          text: "¥35,800",
                          color: "#ebebeb",
                          size: "sm",
                          flex: 0
                        },
                        {
                          type: "text",
                          text: "¥75,000",
                          color: "#ffffffcc",
                          decoration: "line-through",
                          gravity: "bottom",
                          flex: 0,
                          size: "sm"
                        }
                      ],
                      spacing: "lg"
                    },
                    {
                      type: "box",
                      layout: "vertical",
                      contents: [
                        {
                          type: "filler"
                        },
                        {
                          type: "box",
                          layout: "baseline",
                          contents: [
                            {
                              type: "filler"
                            },
                            {
                              type: "icon",
                              url: "https://developers-resource.landpress.line.me/fx/clip/clip14.png"
                            },
                            {
                              type: "text",
                              text: "Add to cart",
                              color: "#ffffff",
                              flex: 0,
                              offsetTop: "-2px",
                              "action": {
                                "type": "uri",
                                "uri": "https://your-cart-url.com"
                              }
                            },
                            {
                              type: "filler"
                            }
                          ],
                          spacing: "sm"
                        },
                        {
                          type: "filler"
                        }
                      ],
                      borderWidth: "1px",
                      cornerRadius: "4px",
                      spacing: "sm",
                      borderColor: "#ffffff",
                      margin: "xxl",
                      height: "40px"
                    }
                  ],
                  position: "absolute",
                  offsetBottom: "0px",
                  offsetStart: "0px",
                  offsetEnd: "0px",
                  backgroundColor: "#03303Acc",
                  paddingAll: "20px",
                  paddingTop: "18px"
                },
                {
                  type: "box",
                  layout: "vertical",
                  contents: [
                    {
                      type: "text",
                      text: "SALE",
                      color: "#ffffff",
                      align: "center",
                      size: "xs",
                      offsetTop: "3px"
                    }
                  ],
                  position: "absolute",
                  cornerRadius: "20px",
                  offsetTop: "18px",
                  backgroundColor: "#ff334b",
                  offsetStart: "18px",
                  height: "25px",
                  width: "53px"
                }
              ],
              paddingAll: "0px"
            },
            footer: {
              type: "box",
              layout: "vertical",
              spacing: "sm",
              contents: [
                // 可以根據需要添加 footer 按鈕
              ]
            }
          },
          {
            type: "bubble",
            hero: null,
            body: {
              type: "box",
              layout: "vertical",
              contents: [
                {
                  type: "image",
                  url: "https://developers-resource.landpress.line.me/fx/clip/clip2.jpg",
                  size: "full",
                  aspectMode: "cover",
                  aspectRatio: "2:3",
                  gravity: "top"
                },
                {
                  type: "box",
                  layout: "vertical",
                  contents: [
                    {
                      type: "box",
                      layout: "vertical",
                      contents: [
                        {
                          type: "text",
                          text: "Cony's T-shirts",
                          size: "xl",
                          color: "#ffffff",
                          weight: "bold"
                        }
                      ]
                    },
                    {
                      type: "box",
                      layout: "baseline",
                      contents: [
                        {
                          type: "text",
                          text: "¥35,800",
                          color: "#ebebeb",
                          size: "sm",
                          flex: 0
                        },
                        {
                          type: "text",
                          text: "¥75,000",
                          color: "#ffffffcc",
                          decoration: "line-through",
                          gravity: "bottom",
                          flex: 0,
                          size: "sm"
                        }
                      ],
                      spacing: "lg"
                    },
                    {
                      type: "box",
                      layout: "vertical",
                      contents: [
                        {
                          type: "filler"
                        },
                        {
                          type: "box",
                          layout: "baseline",
                          contents: [
                            {
                              type: "filler"
                            },
                            {
                              type: "icon",
                              url: "https://developers-resource.landpress.line.me/fx/clip/clip14.png"
                            },
                            {
                              type: "text",
                              text: "Add to cart",
                              color: "#ffffff",
                              flex: 0,
                              offsetTop: "-2px"
                            },
                            {
                              type: "filler"
                            }
                          ],
                          spacing: "sm"
                        },
                        {
                          type: "filler"
                        }
                      ],
                      borderWidth: "1px",
                      cornerRadius: "4px",
                      spacing: "sm",
                      borderColor: "#ffffff",
                      margin: "xxl",
                      height: "40px"
                    }
                  ],
                  position: "absolute",
                  offsetBottom: "0px",
                  offsetStart: "0px",
                  offsetEnd: "0px",
                  backgroundColor: "#9C8E7Ecc",
                  paddingAll: "20px",
                  paddingTop: "18px"
                },
                {
                  type: "box",
                  layout: "vertical",
                  contents: [
                    {
                      type: "text",
                      text: "SALE",
                      color: "#ffffff",
                      align: "center",
                      size: "xs",
                      offsetTop: "3px"
                    }
                  ],
                  position: "absolute",
                  cornerRadius: "20px",
                  offsetTop: "18px",
                  backgroundColor: "#ff334b",
                  offsetStart: "18px",
                  height: "25px",
                  width: "53px"
                }
              ],
              paddingAll: "0px"
            },
            footer: {
              type: "box",
              layout: "vertical",
              spacing: "sm",
              contents: [
                // 可以根據需要添加 footer 按鈕
              ]
            }
          }
        ]
      }
    },
    // 可以在這裡添加更多模板
  ];
  